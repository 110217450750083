import { Link } from "gatsby"
import $ from 'jquery';
import React, { useEffect } from "react"

const Header = ({showLoginButton=false, showLogoutButton=false}) => {

    useEffect(() => {

        $(window).scroll(function(){
            if ($(window).scrollTop() >= 50) {
                $('nav').addClass('fixed-header');
                //$('nav div').addClass('visible-title');
            }
            else {
                $('nav').removeClass('fixed-header');
               // $('nav div').removeClass('visible-title');
            }
        });
    }, [])

    return (
        <>

        <nav className="navbar navbar-expand-md navbar-light">
            <div className="container">
                <a className="navbar-brand" href="https://www.debieratings.com/" target="_blank" rel="noreferrer">
                    <img src="/images/debie-grey.svg" alt="" className="logo" />
                </a>
                {showLoginButton && <Link to="/login" className="nav-link button buttonLogin mobileBtn" >Login</Link>}
                {showLogoutButton && <Link to="/logout" className="nav-link button buttonLogin mobileBtn" >Logout</Link>}
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                          {
                            showLogoutButton ? <Link to="/dashboard" className="nav-link" >Home</Link>
                            : <a className="nav-link" href="#.">Home <span className="sr-only">(current)</span></a>
                          }

                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.debieratings.com/about" target="_blank" rel="noopener noreferrer">About</a></li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://intercom.help/debie/en/collections/2350361-general-faq" target="_blank" rel="noopener noreferrer">FAQ</a>
                        </li>
                        {
                            showLogoutButton &&
                                <li className="nav-item">
                                  <Link to="/account" className="nav-link" >Settings</Link>
                                </li>

                        }
                            {
                                showLoginButton &&
                                <div className="d-flex">
                                    <li>
                                        <Link to="/login" className="nav-link button buttonLogin" >Login</Link>
                                    </li>
                                    {/*<li>
                                    <a className="nav-link button buttonSignUp" href="#">Sign up</a>
                                </li>*/}
                                </div>
                            }
                            {
                                showLogoutButton &&
                                <div className="d-flex">
                                    <li>
                                        <Link to="/logout" className="nav-link button buttonLogin" >Logout</Link>
                                    </li>
                                </div>
                            }
                    </ul>
                </div>
            </div>
        </nav>

        </>
    )
}

export default Header
