import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <>
    <div className="body">
        <div className="section-footer">
            <div className="wave-footer"></div>
            <div className="content-footer">
                <div className="block-content-footer container">
                    <div className="w-layout-grid grid-footer">
                        <a id="w-node-eee9d73e7a64-d73e7a5f" className="link-logo-footer w-inline-block" href="#." >
                            <img src="/images/debie-grey.svg" alt="" className="logo-footer" />
                        </a>
                        <div className="block-footer"></div>
                        <div className="block-footer"></div>
                        <div className="block-footer withContentBlock">
                            <div className="footer-title">Our Company</div>
                            <a href="https://www.debieratings.com/about" target="_blank" rel="noopener noreferrer" className="link-footer">About Us</a>
                            <Link to="/login"  className="link-footer">Login</Link>
                            {/* <a href="#" className="link-footer">Sign Up</a> */}
                            <a href="https://www.debieratings.com/contact-us" target="_blank" rel="noopener noreferrer" className="link-footer">Contact Us</a>
                        </div>
                        <div className="block-footer withContentBlock">
                            <div className="footer-title">Legal &amp; Compliance</div>
                            <a href="https://www.debieratings.com/debie-terms" target="_blank" rel="noopener noreferrer" className="link-footer">Terms of Service</a>
                            <a href="https://www.debieratings.com/debie-privacy-policy" target="_blank" rel="noopener noreferrer" className="link-footer">Privacy &amp; Security</a>
                        </div>
                    </div>
                    <div className="down-footer">
                        <div className="down-links"><strong>© 2020 Debie Business Ratings Agency a division of Debie Corporation.</strong><br /></div>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/debieratings/" className="link-social w-inline-block">
                                <img src="/images/Facebook.svg" alt="" className="icon-facebook" />
                            </a>
                            <a href="https://www.twitter.com/debiehq" className="link-social w-inline-block">
                                <img src="/images/Twitter.svg" alt="" className="icon-twitter" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </>
)

export default Footer
