/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./footer"

import 'react-datetime/css/react-datetime.css'
import './../styles/bootstrap.min.css'
import './../styles/header-footer.css'
import './../styles/style.css'

// import './../styles/normalize.css'
// import './../styles/webflow.css'
// import './../styles/debie1.webflow.css'

const Layout = ({ showLoginButton=true, showLogoutButton=false, children }) => {

    return (
      <>
        <Helmet>
          <title>Debie</title>
          <link href="https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Frank+Ruhl+Libre:wght@300;400;500;700;900&display=swap" rel="stylesheet" />
          <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" type="text/css" />
        </Helmet>
        <Header showLoginButton={showLoginButton} showLogoutButton={showLogoutButton} />
          {children}
        <Footer />
      </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
